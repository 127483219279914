body {
	margin: 0;
	font-family: "DM Sans", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
	/*line-height: 1.2 !important;*/
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */